<template>
  <v-card
    v-if="rvTable != null && hasUserRight('manageResidualValues')"
    flat
    class="px-4"
  >
    <v-card-text>
      <v-row>
        <v-col
          cols="12"
          lg="7"
          md="4"
          sm="6"
          xs="6"
        >
          <v-text-field
            @change="setDirty"
            :label="$t('condAdmin.rvTable.rvTableKey') + $t('base.mandatory')"
            v-model.trim="rvTable.name"
            :rules="[rules.required]"
          />
        </v-col>
        <v-col
          cols="12"
          lg="5"
          md="4"
          sm="6"
          xs="6"
          class="mt-2"
        >
          <v-row
            no-gutters
            class="justify-end"
          >
            <v-btn
              @click.native="$router.back()"
              class="mr-4 prio2"
            >
              {{ $t('base.cancel') }}
            </v-btn>
            <v-btn
              :disabled="!rvTable.isDirty"
              @click.native="save()"
              color="secondary"
              class="mr-4"
            >
              {{ $t('condAdmin.actions.save') }}
            </v-btn>
            <!--<v-menu offset-y>
              <template v-slot:activator="{ on }">
                <v-btn v-on="on">
                  <v-icon>mdi-dots-vertical</v-icon>
                </v-btn>
              </template>

              <v-list>
                <v-list-item
                  :disabled="!enableAction('save')"
                  @click="save()"
                >{{ capitalizeString('condAdmin.actions.save') }}</v-list-item>
                <v-list-item
                  :disabled="!enableAction('setCheck')"
                  @click="setCheckState(rvTable)"
                >{{ capitalizeString('condAdmin.actions.setCheck') }}</v-list-item>
              </v-list>
            </v-menu>-->
          </v-row>
        </v-col>
      </v-row>
      <v-row class="mb-6">
        <v-col
          cols="12"
          lg="2"
          md="2"
          sm="3"
          xs="3"
        >
          <div class="caption">{{ $t('condAdmin.version') }}</div>
          <div class="">{{ rvTable.version }}</div>
        </v-col>
        <v-col
          cols="12"
          lg="2"
          md="2"
          sm="3"
          xs="3"
        >
          <div class="caption">{{ $t('base.state') }} </div>
          <v-chip
            :class="stateClass"
            class=""
          >
            {{ stateText }}
          </v-chip>
        </v-col>
        <v-col
          cols="12"
          lg="2"
          md="2"
          sm="3"
          xs="3"
        >
          {{ $t('condAdmin.validFrom') + ' ' }}
          <DatePicker
            :value="rvTable.validFrom"
            :isBegin="true"
            @change="setProperty('validFrom', $event)"
          />
        </v-col>
        <v-col
          cols="12"
          lg="2"
          md="2"
          sm="3"
          xs="3"
        >
          {{ $t('condAdmin.validTo') + ' ' }}
          <DatePicker
            :value="rvTable.validTo"
            :isEnd="true"
            @change="setProperty('validTo', $event)"
          />
          <!--<span class="text-h6">{{ formatEndDate(condition.validTo) }}</span>-->
        </v-col>
      </v-row>
      <!-- #### additional Data #### -->
      <ResidualValuesVersionDetail
        :params="rvTable || {}"
        :readonly="false"
        :clearable="false"
      />
      <!--<component
        class="pb-4 grey lighten-5"
        v-bind:is="customComponents.ResidualValuesVersionDetail"
        ref="customResidualValues"
        :params="rvTable || {}"
        :readonly="false"
        :clearable="false"
        v-show="customComponents.ResidualValuesVersionDetail != null"
        @setDirty="setDirty"
      ></component>-->
    </v-card-text>
    <SaveOnLeaveDlg ref="leaveDialog" />
  </v-card>
</template>

<script lang="js">
import { setProperty } from '@/base/js/ObjectHelper';
import { removeLock } from '../js/condAdminHelper.js';
import condAdminResidualValuesMixin from '../js/condAdminResidualValuesMixin.js';
import DatePicker from '@/common/DatePicker';
import SaveOnLeaveDlg from '@/common/SaveOnLeaveDlg';
import ResidualValuesVersionDetail from './ResidualValuesVersionDetail';

export default {
  name: 'ResidualValuesEditor',
  components: {
    DatePicker,
    // EditField,
    SaveOnLeaveDlg,
    ResidualValuesVersionDetail
  },
  mixins: [ condAdminResidualValuesMixin ],
  data () {
    return {
      rvTable: this.$store.state.condAdmin.workingRVTable === null ? {} : JSON.parse(JSON.stringify(this.$store.state.condAdmin.workingRVTable)),
      customComponents: {},
      locked: true
    };
  },
  computed: {
    stateClass () {
      return this.getStateClass(this.rvTable);
    },
    stateText () {
      return this.getStateText(this.rvTable);
    }

  },
  async mounted () {
    await this.init();
  },
  async beforeRouteLeave (to, from, next) {
    if (this.rvTable != null && this.rvTable.isDirty) {
      let save = await this.$refs.leaveDialog.open();
      if (save) {
        if (await this.save()) {
          next();
          return false;
        }
      }
    }
    // workingMode == copy || new no Lock is set
    if (this.rvTable != null && this.locked === true && this.rvTable.workingMode === 'edit') {
      await removeLock('rvTable', this.rvTable.rvTableKey, this.rvTable.version);
    }
    next();
  },
  methods: {
    async init () {
      this.rvTable = this.$store.state.condAdmin.workingRVTable;

      if (this.rvTable.versions != null) {
        this.$emit('setPageMode', this.rvTable.workingMode, this.rvTable.name);
        this.getResidualValuesComponents();
      } else {
        this.$router.push('/condAdmin/residualValues');
      }
    },

    getResidualValuesComponents () {
      if (this.$store.state.condAdmin.orgSettings.useCustomizedComponents === true) {
        let shortname = this.$store.state.condAdmin.customComponents.shortname;
        let components = this.$store.state.condAdmin.customComponents.components.filter((c) => c.startsWith('ResidualValues'));

        for (let c of components) {
          this.customComponents[c] = () => import('./custom/' + shortname + '/' + c + '_' + shortname + '.vue');
        }
      }
    },
    setDirty () {
      if (!this.rvTable.isDirty) {
        this.rvTable.isDirty = true;
      }
    },
    enableAction (action) {
      switch (action) {
        case 'save': return this.rvTable.isDirty;
        case 'remove': return this.rvTable.state === 0 || this.rvTable.state === 1;
        case 'setCheck': return !this.rvTable.isDirty;
      }
      return true;
    },
    setProperty (key, value) {
      if (key.includes('.')) setProperty(key, this.rvTable, value);
      else this.rvTable[key] = value;
      this.setDirty();
    },
    /* *******************
    *
    * Base-Settings
    *
    ******************* */

    /* *******************
    *
    * InterestLines
    *
    ******************* */

    /* *******************
    *
    * ResidualValues
    *
    ******************* */
    async save () {
      let processData = await this.saveResidualValues(this.rvTable, false);
      if (processData != null) {
        this.$globals.Info.open([{ type: 'success', message: this.$t('condAdmin.rvTableEditor.infoRVTableSaved') }]);
        this.rvTable.isDirty = false;
        // update AdditionalProductList
        await this.refreshRvTableList();
        this.$store.state.condAdmin.workingRVTable = null;

        this.locked = false;
        this.$router.push('/condAdmin/ResidualValues/' + this.rvTable.rvTableKey + '/' + this.rvTable.version);
      }
    }

  }
};
</script>

<style scoped>
</style>
